import utils from '../utils/api'
// 圈子认证
export function circleAuth(data) {
    return utils({
        url: '/xznc/user/circle/auth',
        method: "POST",
        data
    })
}
// 获取学校
export function schoolPage(params) {
    return utils({
        url: '/xznc/school/page',
        method: "GET",
        params
    })
}
// 新增资讯浏览记录
export function infoBrowseAdd(data) {
    return utils({
        url: '/xznc/info/browse/add',
        method: "POST",
        data
    })
}
// 查询动态浏览记录
// export function statPage(params) {
//     return utils({
//         url: '/xznc/trends/stat/page',
//         method: "GET",
//         params
//     })
// }
// 查询资讯浏览记录
export function browsePage(params) {
    return utils({
        url: '/xznc/info/browse/page',
        method: "GET",
        params
    })
}
// 删除浏览记录
export function browseDel(data) {
    return utils({
        url: '/xznc/info/browse/del',
        method: "POST",
        data
    })
}
// 查询资讯点赞、收藏记录
export function infoStatePage(params) {
    return utils({
        url: '/xznc/info/stat/page',
        method: "GET",
        params
    })
}
// 删除咨询点赞或收藏
export function infoStateDel(data) {
    return utils({
        url: '/xznc/info/stat/del',
        method: "POST",
        data
    })
}
// 查询资讯评论记录
export function commentPage(params) {
    return utils({
        url: '/xznc/comment/page',
        method: "GET",
        params
    })
}
// 资讯删除评论
export function commentBatchDel(data) {
    return utils({
        url: '/xznc/comment/batch/del',
        method: "POST",
        data
    })
}
// 更换手机号
export function setMobile(data) {
    return utils({
        url: '/xznc/user/set/mobile',
        method: "POST",
        data
    })
}
//修改密码
export function updatePwd(data) {
    return utils({
        url: '/xznc/user/update/pwd',
        method: "POST",
        data
    })
}
// 注销账号
export function userLogoff(params) {
    return utils({
        url: '/xznc/user/logoff',
        method: "GET",
        params
    })
}
// 问题反馈 上传图片
export function attUpload(data) {
    return utils({
        url: '/xznc/attr/batch/upload',
        method: "POST",
        data
    })
}
// 提交问题反馈
export function recommendAdd(data) {
    return utils({
        url: '/xznc/recommend/add',
        method: "POST",
        data
    })
}
// 问题反馈列表
export function recommendPage(params) {
    return utils({
        url: '/xznc/recommend/page',
        method: "GET",
        params
    })
}
// 问题反馈详情
export function recommendInfo(params) {
    return utils({
        url: '/xznc/recommend/info/' + params.id,
        method: "GET",
    })
}
// 隐私设置动态管理
export function setTrends(data) {
    console.log(data);
    return utils({
        url: '/xznc/user/set/trends/' + data,
        method: "POST",
    })
}
// 隐私设置添好友
export function setFriend(data) {
    console.log(data);
    return utils({
        url: '/xznc/user/set/friend/' + data,
        method: "POST",
    })
}
// 隐私设置私信聊天
export function setChat(data) {
    console.log(data);
    return utils({
        url: '/xznc/user/set/chat/' + data,
        method: "POST",
    })
}
// 黑名单
export function blackPage(params) {
    return utils({
        url: '/xznc/user/black/page',
        method: "GET",
        params
    })
}
// 圈子我的关注人
export function followMe(params) {
    return utils({
        url: '/xznc/user/follow/me',
        method: "GET",
        params
    })
}
// 圈子我的关注人动态
export function myCollect(data) {
    return utils({
        url: '/xznc/trends/my/collect',
        method: "POST",
        data
    })
}
// 圈子取消我的关注人
export function followCancel(data) {
    return utils({
        url: '/xznc/user/follow/cancel/' + data,
        method: "POST",
    })
}
// 圈子点赞收藏关注统计
export function businessStat(params) {
    return utils({
        url: '/xznc/info/stat/my/business/stat',
        method: "GET",
        params
    })
}
// 圈子/对话/粉丝
export function followTome(params) {
    return utils({
        url: '/xznc/user/follow/tome',
        method: "GET",
        params
    })
}

// 圈子/对话/粉丝关注
export function followAdd(data) {
    return utils({
        url: '/xznc/user/follow/add/' + data.followId,
        method: "POST",
    })
}
// 圈子加入黑名单
export function blackAdd(data) {
    return utils({
        url: '/xznc/user/black/add',
        method: "POST",
        data
    })
}
// 圈子对话评论
export function businessComments(params) {
    return utils({
        url: '/xznc/comment/my/business/comments',
        method: "GET",
        params
    })
}
// 我的动态个人信息
export function userState(params) {
    return utils({
        url: '/xznc/user/stat',
        method: "GET",
        params
    })
}
