import utils from '../utils/api'
export function send(params) {
  return utils({
    url: '/xznc/sms/send',
    method: "GET",
    params
  })
}

export function loginUser(data) {
  return utils({
    url: '/xznc/login/user/sms',
    method: "POST",
    data
  })
}
// 注册
export function userRegister(data) {
  return utils({
    url: '/xznc/login/user/register',
    method: "POST",
    data
  })
}
// 密码登录
export function userLogin(data) {
  return utils({
    url: '/xznc/login/user/login',
    method: "POST",
    data
  })
}
// 找回密码
export function userSetpwd(data) {
  return utils({
    url: '/xznc/login/user/setpwd',
    method: "POST",
    data
  })
}
// 获取用户im登录凭证
export function getUserIMSig() {
  return utils({
    url: '/xznc/im/sig',
    method: "GET"
  })
}
//添加好友时查询用户
export function searchFriend(data) {
  return utils({
    url: '/xznc/user/search',
    method: 'POST',
    data
  })
}
//判断用户是否被关注
export function checkFollow(followId) {
  return utils({
    url: 'xznc/user/follow/confirm/' + followId,
    method: "GET"
  })
}
// 查询用户点赞、收藏、好友等数据
export function getUserStat(followId) {
  return utils({
    url: 'xznc/user/stat?userId=' + followId,
    method: "GET"
  })
}
// 获取用户好友申请列表
export function getApplyFriends(pageNum, pageSize) {
  return utils({
    url: 'xznc/friend/apply/page?pageNum=' + pageNum + "&pageSize=" + pageSize,
    method: "GET"
  })
}
